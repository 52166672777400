<template>
  <div>
    <p v-if="loading"><Loading /></p>
    <div v-if="showResult" class="s-p-3">
      <div class="reward__title py-3">{{ rewardMission.title }}</div>

      <b-card v-if="isSuccess" class="reward success p-4 mb-4" no-body>
        <div class="reward__stamp my-4">
          <img src="./images/stamp.svg" />
        </div>
        <div class="reward__result my-3">集點成功</div>
        <div class="reward__message">
          恭喜您獲得 <span class="s-text-primary">{{ earnPoint }}</span> 點
        </div>
      </b-card>
      <b-card v-else class="reward fail p-4 mb-4" no-body>
        <div class="reward__stamp my-4">
          <img src="./images/stamp.svg" />
        </div>
        <div class="reward__result my-3">集點失敗</div>
        <div class="reward__message">{{ errorMsg }}</div>
      </b-card>

      <button v-if="showConfirmButton" type="button" class="s-btn s-liff-theme-btn-outline mb-2" @click="showBranchCodeForm">重新核銷</button>
      <SharedButton
        class="s-btn-bg-primary"
        @click="$router.push({ name: 'LiffRewardsCardHome', params: {rewardsCardId: rewardMission.reward_card_id} })"
      >
        前往集點卡
      </SharedButton>
    </div>
    <Popup ref="selectBranchPopup" title="請選擇分店" panelHeight="auto" :show-close="false">
      <ShopSelection
        ref="shopSelection"
        v-model="branchId"
        :branch-provider="branchProvider"
        @loading="fetching = true"
        @loaded="fetching = false"
      />
      <div slot="actions">
        <button
          class="s-btn s-liff-theme-btn s-btn-sm"
          type="button"
          :disabled="!branchId"
          @click="handleSelectBranch"
        >
          確定
        </button>
      </div>
    </Popup>
  </div>
</template>


<script>
import Loading from '@/components/Page/Liff/Shared/Loading'
import SharedButton from "@/components/Page/Liff/Shared/Button";
import Popup from '@/components/Page/Liff/Shared/Popup.vue';
import ShopSelection from "@/components/Page/Liff/Branch/ShopSelection";
import rewardMissionApi from "@/apis/liff/v2/reward-mission";
import deepGet from "lodash/get";
import { mapState } from "vuex";

export default {
  components: { Loading, SharedButton, Popup, ShopSelection },
  data() {
    return {
      loading: true,
      showResult: false,
      rewardMission: {},
      rewardCard: {},
      isSuccess: null,
      errorMsg: "",
      earnPoint: 0,
      branchCode: null,
      branchId: null,
      showConfirmButton: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.liffAuth.user,
    }),
    needInputBranch() {
      return deepGet(this.rewardMission, "config.confirm_type", null) === 'input';
    },
    needSelectBranch() {
      return deepGet(this.rewardMission, "config.confirm_type", null) === 'select';
    },
    branchProvider() {
      return deepGet(this.rewardMission, "config.branch_provider", 'waltily.branch');
    },
    needRegisterFirst() {
      return deepGet(this.rewardCard, 'config.permissions', []).includes('registered');
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.fetchMission();

      if (this.needRegisterFirst && !this.user.registered_at) {
        this.$router.push({
          name: "LiffRegisterIndex",
          params: { orgCode: this.$route.params.org_code },
        });
        return;
      }

      if (this.needInputBranch) {
        this.showBranchCodeForm();
      } else if (this.needSelectBranch) {
        this.showSelectBranchPopup();
      } else {
        await this.addPoint();
      }
    },
    showBranchCodeForm() {
      this.loading = false;

      this.$swal
        .fire({
          confirmButtonText: "確認",
          reverseButtons: true,
          showLoaderOnConfirm: true,
          input: "text",
          inputPlaceholder: "請輸入店號",
          allowOutsideClick: false,
          inputValidator: (value) => {
            if (!value) {
              return "店編不能為空";
            }
          },
        })
        .then((result) => {
          this.branchCode = result.value;
          this.addPoint();
        });
    },
    showSelectBranchPopup() {
      this.loading = false;
      this.$refs.selectBranchPopup.open();
      this.$nextTick(() => {
        this.$refs.shopSelection.fetchBranchesOptions();
      });
    },
    handleSelectBranch() {
      this.$refs.selectBranchPopup.close();
      this.addPoint();
    },
    async fetchMission() {
      this.rewardMission = await rewardMissionApi.getRewardMission(this.$route.params.mission_id, { with: ['rewardCard'] });
      this.rewardCard = this.rewardMission.reward_card;
    },
    async addPoint() {
      this.loading = true;
      this.showConfirmButton = false;

      try {
        const resp = await rewardMissionApi.addPoint(this.$route.params.mission_id, {
          branch_id: this.branchId,
          branch_code: this.branchCode
        });
        this.earnPoint = resp.earn_point;
        this.isSuccess = true;
      } catch (error) {
        const code = error.response?.data?.code

        this.showConfirmButton = code === 422;
        this.isSuccess = false;
        this.errorMsg = error.response?.data?.message || '系統錯誤，請稍後再試';
      } finally {
        this.loading = false;
        this.showResult = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reward {
  text-align: center;
  border-radius: 10px;

  border: solid 1px #e5e5ea;

  &__title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  &__stamp {
    border-radius: 100%;
    aspect-ratio: 1/1;
    padding: 8%;
    display: inline-block;
    margin: auto;
  }

  &__result {
    font-weight: 600;
    font-size: 24px;
  }

  &__message {
    /* 恭喜您獲得 1 點 */

    font-weight: 600;
    font-size: 16px;
  }
}

.reward.success {

  background: url(images/reward_bg.png);
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center 2rem;
  .reward {


    &__result {
      color: #00c853;
    }

    &__stamp {
      background-color: var(--s-primary);
      aspect-ratio: 1/1;
      border: solid 1px var(--s-primary);
      box-shadow: 0px 4px 9px rgba(102, 153, 204, 0.2),
        2px 5px 16px rgba(44, 44, 46, 0.15),
        inset 3px 3px 7px rgba(255, 255, 255, 0.8);

      img {
        -webkit-filter: drop-shadow(0px 1px 4px rgba(44, 44, 46, 0.15));
        filter: drop-shadow(0px 1px 4px rgba(44, 44, 46, 0.15));
      }
    }
  }
}

.reward.fail {
  .reward {
    &__result {
      color: #de0071;
    }

    &__stamp {
      background-color: #f2f2f7;
      border: dashed 1px #c0c4cc;

      img {
        content:url(images/stamp_gray.svg);
      }
    }
  }
}
</style>
